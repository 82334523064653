import React from "react"

import Header from "@/components/Header/Header"
import Banner from "@/components/Banner/Banner"
import HomePage from "@/components/HomePage/HomePage"
import Footer from "../components/Footer/Footer"

//import "../styles/main.scss"

export default class Main extends React.Component {
  constructor(props) {
    //console.log('???');
    super(props)
    this.state = {
      bannerIndex: 0
    }
    //this.headerRef=React.createRef();
  }

  //  IsPC=()=> {
  //   let userAgentInfo = navigator.userAgent;
  //   let Agents = ["Android", "iPhone",
  //      "SymbianOS", "Windows Phone",
  //      "iPad", "iPod"];
  //   let flag = true;
  //   for (let v = 0; v < Agents.length; v++) {
  //      if (userAgentInfo.indexOf(Agents[v]) > 0) {
  //         flag = false;
  //         break;
  //      }
  //   }
  //   return flag;
  //}

  CallUsHandle = e => {}

  componentDidMount = () => {
    // if(!this.IsPC()){
    //   window.location.href = "https://mobile.5rs.me/";
    // }
  }
  //https://r.5rs.me/image/match/homePage.png?_=2739
  render() {
    return (
      <div className="Home">
        <Header></Header>
        <Banner
          changeBanner={ind => this.setState({ bannerIndex: ind })}
          width="1920"
          height="785"
          img={[
            "https://oss.5rs.me/oss/uploadfe/png/8a055a30067203912b3d4254d8fee589.png?_=2738",
            "https://oss.5rs.me/oss/uploadfe/png/4c554707519bead76acd2220a758dade.png"
          ]}
        ></Banner>
        <HomePage bannerIndex={this.state.bannerIndex}></HomePage>
        <Footer></Footer>
      </div>
    )
  }
}
