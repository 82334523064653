import React, { useState } from "react"
import { navigate } from "gatsby"

import "./HomePage.scss"

export default props => {
  const [progressIndex, setProgressIndex] = useState(3)
  const bannerIndex = props.bannerIndex

  return (
    <div className="Home">
      <div className="banner-btns">
        {bannerIndex == 0 && (
          <button onClick={e => navigate("/register/enroll")}>立即报名</button>
        )}
      </div>
      <article className="home-content">
        {/* <h1 className="main-title">大赛赛程</h1> */}
        <ul className="progress-list">
          {PROGRESS.map((item, index) => (
            <li key={index}>
              {/* <dl className={index == progressIndex ? "now" : ""}> */}
              <dl>
                <dt>0{index + 1}</dt>
                <dd>
                  <p>{item.title}</p>
                  <h5>
                    {item.date}
                    <label>{item.unit}</label>
                    <span>{item.date2}</span>
                  </h5>
                  <small>{item.summary}</small>
                </dd>
              </dl>
            </li>
          ))}
        </ul>
      </article>
      <img className="next-img" src={require('../../assets/images/next-ico.png').default} alt="" />
      <article className="home-content">
        <h1 className="main-title">大赛奖项</h1>
        <ul className="prize-list">
          {PRIZELIST.map((item, index) => (
            <li key={index}>
              {item.title && (
                <h2>
                  <i></i>
                  {item.title}
                  <i></i>
                </h2>
              )}
              <div className="total-prize">
                <h1>{item.prize}</h1>
                <h3>{item.count}</h3>
              </div>
            </li>
          ))}
          <li className="annual-prize row-common">
            <ul>
              {ANNUALPRIZE.map((item, index) => (
                <li key={index}>
                  {item.title && (
                    <h2>
                      <i></i>
                      {item.title}
                      <i></i>
                    </h2>
                  )}
                </li>
              ))}
            </ul>
            <ul className="total-prize">
              {ANNUALPRIZE.map((item, index) => (
                <li>
                  <h1>{item.prize}</h1>
                  <h3>{item.count}</h3>
                </li>
              ))}
            </ul>
          </li>
          <li className="seasons row-common">
            <h2>
              <i></i>季度奖项<i></i>
            </h2>
            <h3>每季度产生50个获奖名额</h3>
            <ul className="total-prize">
              {SEASONPRIZELIST.map((item, index) => (
                <li>
                  <h1>{item.prize}</h1>
                  <h3>{item.count}</h3>
                  <p>{item.des}</p>
                </li>
              ))}
            </ul>
          </li>
          {/* <li className="seasons row-common">
            <h2>
              <i></i>特别奖项<i></i>
            </h2>
            <ul className="total-prize">
              {SPECIAlPRIZELIST.map((item, index) => (
                <li>
                  <h1>{item.prize}</h1>
                  <h3>{item.count}</h3>
                  <p>{item.des}</p>
                </li>
              ))}
            </ul>
          </li> */}
        </ul>
      </article>

      <article className="home-content">
        <h1 className="main-title">关于我们</h1>
        <div className="about-us">
          <div className="about-us-content">
            <img src={require("../../assets/images/about-us-bg.jpg").default} />
            <div className="about-us-context">
              <h3>
                {/* <img
                  src={
                    require("../../assets/images/about-us-title.png").default
                  }
                /> */}
                国家新闻出版署出版融合发展 (武汉) 重点实验室
              </h3>
              <p>
                　　国家新闻出版署出版融合发展（武汉）重点实验室（以下简称“实验室”），是全国首批{" "}
                <br />
                20
                个出版融合发展重点实验室之一，以武汉理工大学和长江出版传媒股份有限公司为依托
                <br />
                单位，武汉理工数字传播工程有限公司（数传集团）为共建单位，于2016年12月获国家新闻出版署批准建设。目前，实验室是全国300多家出版社、千余家期刊社的出版融合智能服务机构，是中国科技部、中宣部同中央网信办、文化和旅游部在全国出版行业唯一批准的“国家文化和科技融合示范基地”。2020年，实验室与中国新闻出版研究院、中国编辑学会编辑学研究中心、北京印刷学院、武汉理工大学共同成为国家中宣部出版行业“复合数字教材”标准制定的参与方。
              </p>
              <div className="btns">
                <a href="http://www.pilwh.org.cn/" target="_blank">
                  … 详情
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="home-content home-guide">
        <h1 className="main-title">指导单位</h1>
        <ul className="guide-list">
          {GUIDEUNIT.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h1 className="main-title">主办单位</h1>
        <ul className="guide-list">
          {ORGANIZER.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </article>
    </div>
  )
}

const PROGRESS = [
  { title: "第一赛季", date: "1.1-4.30", summary: "征稿" },
  { title: "第二赛季", date: "5.1-7.31", summary: "征稿" },
  { title: "第三赛季", date: "8.1-10.31", summary: "征稿" },
  { title: "第四赛季", date: "11.1-1.31", date2: " ( 2024 ) ", summary: "征稿" }
]

const PRIZELIST = [
  { title: "", prize: "总奖金池¥500,000", count: "共计六大奖项、245个获奖名额" }
]

const ANNUALPRIZE = [
  {
    title: "出版融合·年度组织奖",
    prize: "国家级荣誉表彰",
    count: "编创赛年度优秀参与出版单位 共30家"
  },
  {
    title: "出版融合·年度数智作品奖 ",
    prize: "奖金¥5,000",
    count: "编创赛年度优秀获奖作品 共5组"
  }
]

const SEASONPRIZELIST = [
  {
    prize: "奖金¥1,000",
    count: "出版融合·卓越先行奖 20名",
    des: "积极践行出版融合转型的编辑"
  },
  {
    prize: "奖金¥1,500",
    count: "出版融合·数智作品奖 20组",
    des: "具有融合创新的现代纸书作品"
  },
  {
    prize: "奖金¥2,000",
    count: "出版融合·青年新锐奖 10组",
    des: "具有创新推广意识的编辑"
  }
]

const SPECIAlPRIZELIST = [
  {
    prize: "奖金¥500",
    count: "出版融合·韬光书匠奖 10名",
    des: "专业知识技能大比拼"
  }
]

const GUIDEUNIT = ["中国编辑学会", "中国出版协会", "中国图书评论学会"]
const ORGANIZER = [
  "数字出版智能服务技术教育部工程研究中心",
  "国家文化和科技融合示范基地"
]
